import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"d-flex flex-column h-100 overflow-y-auto",attrs:{"outlined":"","rounded":""}},[_c(VDataTable,{staticClass:"data-table-pointer d-flex flex-column flex-grow-1 overflow-y-auto impersonation-selection-table__table",attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.users.result,"loading":_vm.isUsersLoading,"loading-text":_vm.$t('internSelection.table.isLoading'),"server-items-length":_vm.totalItems,"page":_vm.page,"items-per-page":_vm.pageSize,"footer-props":{
      'items-per-page-text': _vm.$t('userSelection.userTable.itemsPerPageText'),
      'items-per-page-options': [10, 20, 30, 50, -1],
    }},on:{"click:row":_vm.onRowClicked,"update:page":function ($event) { return _vm.onPaginationChanged($event, _vm.pageSize); },"update:items-per-page":function ($event) { return _vm.onPaginationChanged(_vm.page, $event); }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center pa-6"},[_c('no-results'),_c('div',{staticClass:"font-weight-bold empty-state-text--dark mt-3"},[_vm._v(" "+_vm._s(_vm.$t('userSelection.userTable.noData'))+" ")])],1)]},proxy:true},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(_vm.$t('userSelection.userTable.pageFromToActivities', { start: pageStart, end: pageStop, total: itemsLength }))+" ")]}},{key:"item.nameAndItaaNumber",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.person.firstName) + " " + (item.person.lastName) + " (" + (item.person.itaaNr) + ")"))+" ")]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("roles." + (item.role))))+" ")]}},{key:"item.actions",fn:function(){return [_c(VBtn,{attrs:{"icon":"","elevation":"0"}},[_c(VIcon,[_vm._v("$caret-next")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }