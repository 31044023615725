




















































import { Component, Vue } from 'vue-property-decorator';
import { IPagedResult } from '@/contracts/paging.contract';
import { IUser } from '@/store/modules/user/user.module';
import ImpersonationSelectionTable from '@/components/tables/ImpersonationSelectionTable.vue';
import { apiService } from '@/services/api.service';
import SearchUser from '@/assets/svg/search-user.svg'

@Component({
  components: { ImpersonationSelectionTable, SearchUser },
})
export default class ImpersonationSelectionView extends Vue {
  public nameOrItaaNumber = '';
  public users = {} as IPagedResult<IUser>;
  public page = 1;
  public pageSize = 20;
  public isUsersLoading = false;
  public storedFilter = '';

  public async searchUser(): Promise<void> {
    this.isUsersLoading = true;
    const persons = await apiService.getUsers({
      q: this.nameOrItaaNumber,
      skip: ((this.page || 1) - 1) * (this.pageSize || 20),
      take: this.pageSize || 20,
    });
    this.users = {
      total: persons.total,
      skip: persons.skip,
      take: persons.take,
      result: persons.result.map(person => {
        return {
          person: person,
          role: person.roles[0]
        }
      })
    }
    this.isUsersLoading = false;
  }

  public async storeFilterAndSearch(): Promise<void> {
    this.storedFilter = this.nameOrItaaNumber;
    await this.searchUser();
  }

  public get filterChanged(): boolean {
    return this.nameOrItaaNumber !== this.storedFilter;
  }

  public async pageChange(pageInfo: { page: number; pageSize: number }): Promise<void> {
    this.page = pageInfo.page;
    this.pageSize = pageInfo.pageSize;
    await this.searchUser();
  }
}
