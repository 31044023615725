






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { IUser, userModule } from '@/store/modules/user/user.module';
import { IPagedResult } from '@/contracts/paging.contract';
import NoResults from '@/assets/svg/no-results.svg';

@Component({components: {NoResults}})
export default class ImpersonationSelectionTable extends Vue {

  @Prop()
  public users!: IPagedResult<IUser>;
  @Prop()
  public isUsersLoading = false;
  @Prop()
  public page!: number;
  @Prop()
  public pageSize!: number;
  @Prop()
  public nameOrItaaNumber!: string;

  public onRowClicked(user: IUser): void {
    userModule.impersonate(user);
    this.$router.push({ name: this.Routes.AssignmentOverview });
  }

  public onPaginationChanged(page: number, pageSize: number): void {
    this.$emit('page-change', {
      page,
      pageSize,
    });
  }

  public get totalItems(): number {
    return this.users.total ?? 10000;
  }

  public get headers(): DataTableHeader[] {
    const properties = ['nameAndItaaNumber', 'role'];
    return [
      ...properties.map((prop) => ({
        text: this.$t(`userSelection.userTable.headers.${prop}`).toString(),
        value: prop,
        class: `impersonation-table__header--${prop}`,
      })),
      {
        text: '',
        value: 'actions',
        width: '4rem',
      },
    ];
  }
}
